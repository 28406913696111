import { type ReactElement } from 'react';
import { getConfig } from '@customer-frontend/config';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { Maybe, ProblemType } from '@customer-frontend/graphql-types';
import { getZendeskRequestUrl } from '@customer-frontend/utils';
import { Typography } from '@eucalyptusvc/design-system';

type DoctorAssignedCardProps = {
  consultationId: string;
  doctorName: string;
  allowPatientToSendMessageToDoctor: boolean;
  hasPractitionerMessaged: boolean;
  isSyncConsult?: boolean;
  latestPractitionerBooking?: Maybe<{ id: string }>;
  hasChatThread: boolean;
  hasActiveWeightTreatment?: boolean;
  routes: {
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
      review: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
  problemType: ProblemType;
};

export function DoctorAssignedCard({
  consultationId,
  doctorName,
  allowPatientToSendMessageToDoctor,
  hasPractitionerMessaged,
  isSyncConsult,
  latestPractitionerBooking,
  hasChatThread,
  hasActiveWeightTreatment,
  routes,
  problemType,
}: DoctorAssignedCardProps): ReactElement {
  const config = getConfig();
  const history = useHistory();

  const helpLink = getZendeskRequestUrl({ problemType });

  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  const chatButton = hasChatThread
    ? {
        key: 'doctorAssignedCardButtonChat',
        text: <FormattedMessage defaultMessage="Open consult chat" />,
        onClick: () => history.push(routes.consultation.chat(consultationId)),
        level: 'primary' as const,
      }
    : {
        key: 'doctorAssignedCardButtonConsult',
        text: asyncConsultsEnabled ? (
          <FormattedMessage defaultMessage="Open consult chat" />
        ) : (
          <FormattedMessage
            defaultMessage="View consult"
            description="Button in profile to open up your consultation to send message to practitioner"
          />
        ),
        onClick: () => history.push(routes.consultation.review(consultationId)),
        level: 'primary' as const,
      };

  const buttons = [];

  if (allowPatientToSendMessageToDoctor) {
    if (hasPractitionerMessaged || isSyncConsult) {
      buttons.push(chatButton);
    }
  }
  if (hasActiveWeightTreatment && config.weightDashboardEnabled) {
    buttons.push({
      key: 'doctorAssignedCardButtonDashboard',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
      level: allowPatientToSendMessageToDoctor
        ? ('secondary' as const)
        : ('primary' as const),
    });
  }

  if (isSyncConsult && latestPractitionerBooking) {
    return (
      <ConsultationCardContentView
        paragraphs={
          asyncConsultsEnabled
            ? [
                {
                  key: 'doctorAssignedCardText0',
                  text: (
                    <Typography size="medium-paragraph" isBold>
                      <FormattedMessage
                        defaultMessage="{doctorName} is reviewing your information and will be in touch soon."
                        values={{
                          doctorName,
                        }}
                      />
                    </Typography>
                  ),
                },
                {
                  key: 'doctorAssignedCardText1',
                  text: (
                    <Typography size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="If you have questions about your treatment, please send them in the chat. Your {isGb, select, true {prescriber} other {practitioner}} will answer them during your consult."
                        values={{ isGb: config.countryCode === 'GB' }}
                      />
                    </Typography>
                  ),
                },
                {
                  key: 'doctorAssignedCardText2',
                  text: (
                    <Typography size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
                        values={{
                          a: (chunks) => (
                            <a
                              href={helpLink}
                              target="_blank"
                              rel="noreferrer"
                              className="text-link"
                            >
                              {chunks}
                            </a>
                          ),
                        }}
                      />
                    </Typography>
                  ),
                },
                {
                  key: 'doctorAssignedCardText3',
                  text: (
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
                    </Typography>
                  ),
                },
              ]
            : [
                {
                  key: 'doctorAssignedCardText',
                  text: (
                    <FormattedMessage
                      defaultMessage="{doctorName} is reviewing your information and will call you shortly. {allowPatientToSendMessageToDoctor, select, true {Feel free to leave questions in the chat.} other {}}"
                      description="Description in profile to show that practitioner will call soon and they can open chat page"
                      values={{
                        doctorName,
                        allowPatientToSendMessageToDoctor,
                      }}
                    />
                  ),
                },
              ]
        }
        buttons={buttons}
      />
    );
  }

  const paragraphs = [];
  if (asyncConsultsEnabled) {
    paragraphs.push({
      key: 'doctorAssignedCardText0',
      text: (
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage
            defaultMessage="{doctorName} is reviewing your information and will be in touch soon."
            values={{
              doctorName,
            }}
          />
        </Typography>
      ),
    });

    if (hasPractitionerMessaged) {
      paragraphs.push({
        key: 'doctorAssignedCardText1',
        text: (
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="If you have questions about your treatment, please send them in the chat. Your {isGb, select, true {prescriber} other {practitioner}} will answer them during your consult."
              values={{ isGb: config.countryCode === 'GB' }}
            />
          </Typography>
        ),
      });
    }
    paragraphs.push({
      key: 'doctorAssignedCardText2',
      text: (
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
            values={{
              a: (chunks) => (
                <a
                  href={helpLink}
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
      ),
    });
    paragraphs.push({
      key: 'doctorAssignedCardText3',
      text: (
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
        </Typography>
      ),
    });
  } else {
    paragraphs.push({
      key: 'doctorAssignedCardText',
      text: (
        <FormattedMessage
          defaultMessage="{doctorName} is currently going through your information and will be in touch soon."
          description="Description in profile to show that practitioner is reviewing the quiz answers and will in touch soon"
          values={{
            doctorName,
          }}
        />
      ),
    });
  }
  return (
    <ConsultationCardContentView paragraphs={paragraphs} buttons={buttons} />
  );
}
