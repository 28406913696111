import moment from 'moment';
import { Button, Card, Divider, Typography } from '@eucalyptusvc/design-system';
import { PhotoAngleType } from '@customer-frontend/graphql-types';
import { ProgressPreview } from './progress-preview';
import { Variant } from './types';
import { useSkinTrackerVariant } from './use-skin-tracker-variant';
import { FaCamera } from 'react-icons/fa';
import { WhatIsThisModalButton } from './what-is-this-modal';
import { useHistory } from 'react-router-dom';
import { progressTrackerRoutes } from 'utils/routes';

export type SkinTrackerProfileCardProps = {
  progressEntries?: {
    photos?:
      | ({ angle: PhotoAngleType; signedUrl: string } | undefined | null)[]
      | null;
    date?: string | undefined | null;
  }[];
  treatmentStartDate?: string | undefined | null;
  variant: Variant;
};

export const SkinTrackerProfileCard: React.FC<SkinTrackerProfileCardProps> = ({
  progressEntries = [],
  treatmentStartDate,
  variant,
}) => {
  const history = useHistory();

  const entries = [...progressEntries];
  const sortedEntries = entries.sort((a, b) =>
    moment(b.date).diff(moment(a.date)),
  );

  const {
    title,
    checklist,
    showHelperModal,
    photoTitles,
    previewPhotos,
    description,
    startSkinQuiz,
  } = useSkinTrackerVariant({
    treatmentStartDate,
    progressEntries,
    variant,
  });

  return (
    <Card>
      <div className="space-y-4 relative">
        <div className="flex items-center space-x-2 md:pb-1">
          <FaCamera size="24px" />
          <Typography size="md" isBold>
            {title}
          </Typography>
        </div>
        <Divider mt="lg" />
        <div className="py-2 md:pr-32">
          <Typography size="medium-paragraph">{description}</Typography>
        </div>
        <ProgressPreview
          progressEntries={sortedEntries}
          startSkinQuiz={startSkinQuiz}
          photoTitles={photoTitles}
          previewPhotos={previewPhotos}
        />
        {checklist}
        <div className="flex flex-col items-center space-y-4">
          <Button
            level="secondary"
            isFullWidth
            onClick={() => history.push(progressTrackerRoutes.root)}
          >
            View progress
          </Button>
          {showHelperModal && (
            <div>
              <WhatIsThisModalButton />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export { Variant };
