import { ReactElement } from 'react';
import { PurchaseActiveCardPurchaseFragment } from '@customer-frontend/graphql-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardProps } from '../consultation/types';
import { ConsultationCardContentView } from '../consultation/components/consultation-card-content-view';
import { gql } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';

type PurchaseActiveCardProps = {
  purchase: PurchaseActiveCardPurchaseFragment;
  routes: {
    offering: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
  children: React.ReactNode;
};

export function PurchaseActiveCard({
  purchase,
  routes,
  children,
}: PurchaseActiveCardProps): ReactElement | null {
  const history = useHistory();
  const config = getConfig();

  if (!purchase.offering) {
    return null;
  }

  const showDashboardButton =
    purchase.offering.problemTypes?.includes('WEIGHT_LOSS') &&
    config.weightDashboardEnabled;

  const buttons: ConsultationCardProps['buttons'] = [
    {
      key: 'purchaseActiveCardViewButton',
      text: (
        <FormattedMessage
          defaultMessage="View treatment plan"
          description="Button that navigates to the treatment plan details page"
        />
      ),
      onClick: () => {
        history.push(routes.offering.plan(purchase.id));
      },
      level: showDashboardButton ? 'secondary' : 'primary',
    },
  ];

  if (showDashboardButton) {
    buttons.unshift({
      key: 'purchaseActiveCardWeightButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
      level: 'primary' as const,
    });
  }

  return (
    <ConsultationCardContentView paragraphs={[]} buttons={buttons}>
      {children}
    </ConsultationCardContentView>
  );
}

PurchaseActiveCard.fragment = gql`
  fragment PurchaseActiveCardPurchase on Purchase {
    id
    offering {
      id
      problemTypes
    }
  }
`;
