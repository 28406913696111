import {
  FulfillmentStatus,
  TreatmentStatus,
} from '@customer-frontend/graphql-types';
import { differenceInCalendarDays } from 'date-fns';
import { findFirstFulfilledOrderDate } from '../utils';

export enum CheckInQuizStatus {
  SHOW,
  RESUME,
  HIDE,
  RESULTS,
}

type UseShowCheckInLogicProps = {
  customerStatedTreatmentStartedAt?: string | null;
  treatment?: {
    status: TreatmentStatus;
    orders: {
      fulfillment?: FulfillmentStatus | null | undefined;
      fulfilledAt?: string | null | undefined;
    }[];
  } | null;
  mostRecentCheckInQuiz?: {
    quiz: {
      quizCode: string;
    };
    createdAt: string;
    submittedAt?: string | null | undefined;
  } | null;
};

export const getCheckInQuizStatus = ({
  customerStatedTreatmentStartedAt,
  mostRecentCheckInQuiz,
  treatment,
}: UseShowCheckInLogicProps): CheckInQuizStatus => {
  const firstFulfilledOrderDate = findFirstFulfilledOrderDate(
    treatment?.orders,
  );

  if (treatment?.status === 'CANCELED') {
    return CheckInQuizStatus.HIDE;
  }

  //Nothing has been fulfilled yet so don't show a check in quiz
  if (!firstFulfilledOrderDate) {
    return CheckInQuizStatus.HIDE;
  }

  const startDate = customerStatedTreatmentStartedAt || firstFulfilledOrderDate;

  const hasBeenTwoWeeksSinceStart =
    differenceInCalendarDays(new Date(), new Date(startDate)) > 14;

  if (!mostRecentCheckInQuiz) {
    return hasBeenTwoWeeksSinceStart
      ? CheckInQuizStatus.SHOW
      : CheckInQuizStatus.HIDE;
  }

  if (!mostRecentCheckInQuiz.submittedAt) {
    return CheckInQuizStatus.RESUME;
  }

  const hasBeenTwoWeeksSinceSubmitted =
    differenceInCalendarDays(
      new Date(),
      new Date(mostRecentCheckInQuiz.submittedAt),
    ) > 14;

  return hasBeenTwoWeeksSinceSubmitted
    ? CheckInQuizStatus.SHOW
    : CheckInQuizStatus.RESULTS;
};
