import { useConsultationFlowConfig } from '@customer-frontend/config';
import { activeReminder } from '@customer-frontend/consultation';
import { UseConsultationCardContentConsultationFragment } from '@customer-frontend/graphql-types';
import { ReactElement, useMemo } from 'react';
import { usePresciberNameFromDoctor } from '@customer-frontend/doctor';
import { AwaitingAnswersCard } from '../components/awaiting-answers-card';
import { AwaitingDoctorCard } from '../components/awaiting-doctor-card';
import { AwaitingPaymentCard } from '../components/awaiting-payment-card';
import { AwaitingResultsCard } from '../components/awaiting-results';
import { AwaitingSurveyCard } from '../components/awaiting-survey-card';
import { DoctorAssignedCard } from '../components/doctor-assigned-card';
import { DoctorRejectedCard } from '../components/doctor-rejected-card';
import { ReminderCard } from '../components/reminder-card';
import { ReviewAwaitingSurveyCard } from '../components/review-awaiting-survey-card';
import { TreatmentActiveCard } from '../components/treatment-active-card';
import { TreatmentCancelledCard } from '../components/treatment-cancelled-card';
import { TreatmentCreatedCard } from '../components/treatment-created-card';
import { TreatmentFollowUpCard } from '../components/treatment-follow-up-card';
import { TreatmentPausedCard } from '../components/treatment-paused-card';
import { TreatmentProcessingOrderCard } from '../components/treatment-processing-order-card';
import { TreatmentReviewAwaitingDoctorCard } from '../components/treatment-review-awaiting-doctor-card';
import { TreatmentReviewAwaitingSurveyCard } from '../components/treatment-review-awaiting-survey-card';
import { useIntl } from 'react-intl';
import {
  TreatmentDetails,
  UseCustomTreatmentDetails,
} from '../../treatment/treatment-details';
import { InitialPurchasePromptCard } from '../components/initial-purchase-prompt-card';
import { SubstitutePurchasePromptCard } from '../components/substitute-purchase-prompt-card';
import { FurPurchasePromptCard } from '../components/fur-purchase-prompt-card';
import { ConfirmPurchasePromptCard } from '../components/confirm-purchase-prompt-card';
import { RestartFlowPromptCard } from '../components/restart-flow-prompt-card';
import { RefetchQueriesInclude, gql } from '@apollo/client';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

type UseConsultationCardParams = {
  consultation: UseConsultationCardContentConsultationFragment;
  routes: {
    quiz: {
      root: (id: string) => string;
    };
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
      review: (id: string) => string;
      followUp: (id: string) => string;
      collectAddress: (id: string) => string;
      collectMedicare: (id: string) => string;
      phoneCall: (id: string) => string;
      scheduleCall: (id: string) => string;
      consultationPayment: (id: string) => string;
      upfrontPayment: (
        id: string,
        options?: {
          couponCode?: string | null;
        },
      ) => string;
      reviewFollowUpOrReview: (id: string) => string;
      reviewSubstitution: (id: string) => string;
      reason: (id: string) => string;
      plan: (id: string) => string;
      activatePurchaseFlow: {
        review: (id: string) => string;
      };
    };
    treatment: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
    profile: string;
  };
  refetchQueries?: RefetchQueriesInclude;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
};

export function useConsultationCardContent({
  consultation,
  routes,
  refetchQueries,
  useCustomTreatmentDetails,
}: UseConsultationCardParams): ReactElement {
  const { formatMessage } = useIntl();
  const doctorName = usePresciberNameFromDoctor(consultation.doctor);
  const consultConfig = useConsultationFlowConfig(consultation.type);

  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  const isSyncConsult = !!(
    (asyncConsultsEnabled || consultation.requiresPrescriberCall) &&
    consultConfig?.requiresPrescriberBooking
  );
  const hasChatThread = !!consultation.chatThread;

  const hasActiveWeightTreatment = !!(
    consultation.type === 'WEIGHT_LOSS' && consultation.treatment?.active
  );

  return useMemo(() => {
    const isFollowUpOrReview = ['FOLLOW_UP', 'REVIEW'].includes(
      consultation.stage,
    );

    if (consultation.treatment && !consultation.prescribedSequence) {
      const treatmentCancelledWithoutOpenConsultation =
        consultation.treatment.status === 'CANCELED' &&
        typeof consultation.isApproved === 'boolean';

      if (treatmentCancelledWithoutOpenConsultation) {
        return <TreatmentCancelledCard />;
      }

      if (consultation.status === 'AWAITING_ANSWERS') {
        return (
          <AwaitingAnswersCard
            consultationId={consultation.id}
            doctorName={doctorName}
            hasChatThread={hasChatThread}
            routes={routes}
            problemType={consultation.type}
          />
        );
      }

      if (consultation.treatment?.status === 'CREATED') {
        if (consultation.status === 'DOCTOR_ASSIGNED') {
          return (
            <DoctorAssignedCard
              consultationId={consultation.id}
              doctorName={doctorName}
              allowPatientToSendMessageToDoctor={
                consultation.allowPatientToSendMessageToDoctor
              }
              hasPractitionerMessaged={!!consultation.practitionerMessagedAt}
              isSyncConsult={isSyncConsult}
              hasChatThread={hasChatThread}
              routes={routes}
              problemType={consultation.type}
            />
          );
        }
        return (
          <TreatmentCreatedCard
            consultationId={consultation.id}
            doctorName={doctorName}
            isFollowUpOrReview={isFollowUpOrReview}
            routes={routes}
          />
        );
      }

      if (consultation.treatment?.status === 'FOLLOW_UP') {
        if (
          consultation.status === 'DOCTOR_COMPLETED' &&
          consultation.isApproved
        ) {
          return (
            <TreatmentCreatedCard
              consultationId={consultation.id}
              doctorName={doctorName}
              isDashboardActive={hasActiveWeightTreatment}
              isFollowUpOrReview={isFollowUpOrReview}
              routes={routes}
            />
          );
        }

        if (consultation.status === 'AWAITING_SURVEY') {
          return (
            <TreatmentFollowUpCard
              consultationId={consultation.id}
              pendingFollowUpReminderDate={
                consultation.treatment.pendingFollowUpReminderDate
              }
              isLastOrderUnfulfilled={
                consultation.treatment.isLastOrderUnfulfilled
              }
              problemType={consultation.type}
              treatment={consultation.treatment}
              quizApplicationSubmitted={
                !!consultation.quizApplication?.submittedAt
              }
              routes={routes}
            />
          );
        }
        if (consultation.status === 'AWAITING_DOCTOR' && !isSyncConsult) {
          return (
            <TreatmentReviewAwaitingDoctorCard
              consultationId={consultation.id}
              hasActiveWeightTreatment={hasActiveWeightTreatment}
              hasChatThread={hasChatThread}
              routes={routes}
            >
              <TreatmentDetails
                treatment={consultation.treatment}
                status={formatMessage({
                  defaultMessage: 'Follow up pending',
                  description:
                    'Text status on the profile to show that the consultation waiting for a follow up to be completed',
                })}
              />
            </TreatmentReviewAwaitingDoctorCard>
          );
        }
      }

      if (
        [
          'AWAITING_VERIFY_ID',
          'PROCESSING_PAYMENT',
          'PAYMENT_FAILED',
          'PROCESSING_ORDER',
        ].includes(consultation.treatment?.status ?? '')
      ) {
        return (
          <TreatmentProcessingOrderCard
            treatment={consultation.treatment}
            consultationStatus={consultation.status}
            problemType={consultation.type}
            useCustomTreatmentDetails={useCustomTreatmentDetails}
            routes={routes}
          />
        );
      }

      if (consultation.treatment.status === 'ACTIVE') {
        return (
          <TreatmentActiveCard
            treatment={consultation.treatment}
            problemType={consultation.type}
            useCustomTreatmentDetails={useCustomTreatmentDetails}
            routes={routes}
          />
        );
      }

      if (consultation.treatment.status === 'PAUSED') {
        return (
          <TreatmentPausedCard
            treatment={consultation.treatment}
            refetchQueries={refetchQueries}
            useCustomTreatmentDetails={useCustomTreatmentDetails}
          />
        );
      }

      if (consultation.treatment.status === 'REVIEW') {
        if (
          consultation.status === 'DOCTOR_COMPLETED' &&
          consultation.isApproved
        ) {
          return (
            <TreatmentCreatedCard
              consultationId={consultation.id}
              doctorName={doctorName}
              isFollowUpOrReview={isFollowUpOrReview}
              routes={routes}
            />
          );
        }
        if (consultation.status === 'AWAITING_SURVEY') {
          return (
            <TreatmentReviewAwaitingSurveyCard
              consultationId={consultation.id}
              hasActiveWeightTreatment={hasActiveWeightTreatment}
              problemType={consultation.type}
              quizApplicationSubmitted={
                !!consultation.quizApplication?.submittedAt
              }
              routes={routes}
            >
              <TreatmentDetails
                treatment={consultation.treatment}
                status={formatMessage({
                  defaultMessage: 'In review',
                  description:
                    'Text status on the profile to show that the consultation is in review',
                })}
              />
            </TreatmentReviewAwaitingSurveyCard>
          );
        }
        if (consultation.status === 'AWAITING_DOCTOR' && !isSyncConsult) {
          return (
            <TreatmentReviewAwaitingDoctorCard
              consultationId={consultation.id}
              hasActiveWeightTreatment={hasActiveWeightTreatment}
              hasChatThread={hasChatThread}
              routes={routes}
            >
              <TreatmentDetails
                treatment={consultation.treatment}
                status={formatMessage({
                  defaultMessage: 'Review pending',
                  description:
                    'Text status on the profile to show that the consultation waiting for a review to be completed',
                })}
                useCustomTreatmentDetails={useCustomTreatmentDetails}
              />
            </TreatmentReviewAwaitingDoctorCard>
          );
        }
        if (consultation.status === 'DOCTOR_ASSIGNED') {
          return (
            <DoctorAssignedCard
              consultationId={consultation.id}
              doctorName={doctorName}
              allowPatientToSendMessageToDoctor={
                consultation.allowPatientToSendMessageToDoctor
              }
              hasPractitionerMessaged={!!consultation.practitionerMessagedAt}
              isSyncConsult={isSyncConsult}
              hasChatThread={hasChatThread}
              latestPractitionerBooking={consultation.latestPractitionerBooking}
              hasActiveWeightTreatment={hasActiveWeightTreatment}
              routes={routes}
              problemType={consultation.type}
            />
          );
        }
        if (consultation.status === 'AWAITING_RESULTS') {
          return (
            <AwaitingResultsCard
              pathologyRequests={consultation.pathologyRequests}
            />
          );
        }
      }
    }

    if (
      consultation.stage === 'REVIEW' &&
      consultation.status === 'AWAITING_SURVEY'
    ) {
      return (
        <ReviewAwaitingSurveyCard
          consultationId={consultation.id}
          treatment={consultation.treatment}
          useCustomTreatmentDetails={useCustomTreatmentDetails}
          routes={routes}
          requiresPhoneCall={consultation.requiresPrescriberCall}
          quizApplicationSubmitted={!!consultation.quizApplication?.submittedAt}
        />
      );
    }

    if (consultation.typeIsFlexi) {
      switch (consultation.status) {
        case 'AWAITING_SURVEY':
          return (
            <AwaitingSurveyCard
              consultationId={consultation.id}
              problemType={consultation.type}
              isSyncConsult={isSyncConsult}
              screeningQuizApplication={consultation.screeningQuizApplication}
              quizApplicationSubmitted={
                !!consultation.quizApplication?.submittedAt
              }
              routes={routes}
              requiresPhoneCall={consultation.requiresPrescriberCall}
            />
          );
      }

      switch (consultation.purchasePrompt?.__typename) {
        case 'RestartFlowPrompt':
          return (
            <RestartFlowPromptCard
              problemType={consultation.type}
              routes={routes}
            />
          );
        case 'InitialPurchasePrompt':
          return (
            <InitialPurchasePromptCard
              consultationId={consultation.id}
              isSyncConsult={isSyncConsult}
              routes={routes}
            />
          );
        case 'FurPurchasePrompt':
          return (
            <FurPurchasePromptCard
              consultationId={consultation.id}
              practitionerName={doctorName}
              routes={routes}
            />
          );
        case 'SubstitutePurchasePrompt':
          return (
            <SubstitutePurchasePromptCard
              consultationId={consultation.id}
              practitionerName={doctorName}
              routes={routes}
            />
          );
        case 'ConfirmPurchasePrompt':
          return (
            <ConfirmPurchasePromptCard
              consultationId={consultation.id}
              practitionerName={doctorName}
              routes={routes}
            />
          );
      }
    }

    if (consultation?.reminders) {
      const reminder = activeReminder(consultation);

      if (reminder !== null) {
        return (
          <ReminderCard
            consultationId={consultation.id}
            reminder={reminder}
            doctorName={doctorName}
            hasChatThread={hasChatThread}
            routes={routes}
          />
        );
      }
    }

    switch (consultation.status) {
      case 'AWAITING_SURVEY':
        return (
          <AwaitingSurveyCard
            consultationId={consultation.id}
            problemType={consultation.type}
            isSyncConsult={isSyncConsult}
            screeningQuizApplication={consultation.screeningQuizApplication}
            quizApplicationSubmitted={
              !!consultation.quizApplication?.submittedAt
            }
            routes={routes}
            requiresPhoneCall={consultation.requiresPrescriberCall}
          />
        );
      case 'AWAITING_PAYMENT':
        return (
          <AwaitingPaymentCard
            consultationId={consultation.id}
            problemType={consultation.type}
            isSyncConsult={isSyncConsult}
            isUpfrontPayment={consultConfig?.isUpfrontPayment ?? false}
            routes={routes}
          />
        );
      case 'AWAITING_DOCTOR':
        return (
          <AwaitingDoctorCard
            consultationId={consultation.id}
            isSyncConsult={isSyncConsult}
            allowPatientToSendMessageToDoctor={
              consultation.allowPatientToSendMessageToDoctor
            }
            hasPractitionerMessaged={!!consultation.practitionerMessagedAt}
            isInNoPickUpQueue={!!consultation.isInNoPickUpQueue}
            practitionerBooking={consultation.latestPractitionerBooking}
            routes={routes}
            problemType={consultation.type}
          />
        );
      case 'DOCTOR_ASSIGNED':
        return (
          <DoctorAssignedCard
            consultationId={consultation.id}
            doctorName={doctorName}
            allowPatientToSendMessageToDoctor={
              consultation.allowPatientToSendMessageToDoctor
            }
            hasPractitionerMessaged={!!consultation.practitionerMessagedAt}
            isSyncConsult={isSyncConsult}
            latestPractitionerBooking={consultation.latestPractitionerBooking}
            hasChatThread={hasChatThread}
            hasActiveWeightTreatment={hasActiveWeightTreatment}
            routes={routes}
            problemType={consultation.type}
          />
        );
      case 'AWAITING_ANSWERS':
        return (
          <AwaitingAnswersCard
            consultationId={consultation.id}
            doctorName={doctorName}
            hasChatThread={hasChatThread}
            routes={routes}
            problemType={consultation.type}
          />
        );
      case 'DOCTOR_COMPLETED': {
        if (consultation.typeIsFlexi && consultation.isApproved) {
          // We expect the card to not have been rendered.
          throw new Error('Not implemented');
        }

        return (
          <DoctorRejectedCard
            consultationId={consultation.id}
            doctorName={doctorName}
            routes={routes}
          />
        );
      }
      case 'AWAITING_RESULTS':
        return (
          <AwaitingResultsCard
            pathologyRequests={consultation.pathologyRequests}
          />
        );
      case 'CANCELED':
        return <TreatmentCancelledCard />;
      default:
        throw new Error('Not implemented');
    }
  }, [
    consultation,
    doctorName,
    formatMessage,
    hasActiveWeightTreatment,
    hasChatThread,
    isSyncConsult,
    refetchQueries,
    routes,
    useCustomTreatmentDetails,
    consultConfig?.isUpfrontPayment,
  ]);
}

useConsultationCardContent.fragment = gql`
  fragment UseConsultationCardContentConsultation on Consultation {
    id
    type
    stage
    status
    isApproved
    typeIsFlexi
    requiresPrescriberCall
    isInNoPickUpQueue
    allowPatientToSendMessageToDoctor
    chatThread {
      id
    }
    doctor {
      id
      fullName
      fullClinicianName
    }
    treatment {
      id
      status
      active
      pendingFollowUpReminderDate
      isLastOrderUnfulfilled
      ...TreatmentFollowUpCardTreatment
      ...TreatmentPausedCardTreatment
      ...ReviewAwaitingSurveyCardTreatment
      ...TreatmentActiveCardTreatment
      ...TreatmentDetailsTreatment
      ...TreatmentProcessingOrderCardTreatment
    }
    prescribedSequence {
      id
    }
    quizApplication {
      id
      submittedAt
    }
    screeningQuizApplication {
      id
      ...AwaitingSurveyCardScreeningQuizApplication
    }
    latestPractitionerBooking {
      id
      ...AwaitingDoctorCardPractitionerBooking
    }
    pathologyRequests {
      id
      customerStatedCollectedAt
      ...AwaitingResultsCardPathologyRequest
    }
    reminders {
      id
      ...ActiveReminderReminders
      ...ReminderCardReminder
    }
    patientQuestions {
      id
      ...ActiveReminderPatientQuestions
    }
    purchasePrompt {
      id
    }
    practitionerMessagedAt
  }

  ${TreatmentFollowUpCard.fragment}
  ${TreatmentPausedCard.fragment}
  ${ReviewAwaitingSurveyCard.fragment}
  ${AwaitingResultsCard.fragment}
  ${AwaitingSurveyCard.fragment}
  ${AwaitingDoctorCard.fragment}
  ${activeReminder.fragment}
  ${ReminderCard.fragment}
  ${TreatmentActiveCard.fragment}
  ${TreatmentDetails.fragment}
  ${TreatmentProcessingOrderCard.fragment}
`;
