import React, { type ReactElement } from 'react';
import type { PurchaseCompletedCardPurchaseFragment } from '@customer-frontend/graphql-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardProps } from '../consultation/types';
import { ConsultationCardContentView } from '../consultation/components/consultation-card-content-view';
import { gql } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';

type PurchaseCompletedCardProps = {
  purchase: PurchaseCompletedCardPurchaseFragment;
  routes: {
    offering: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
  children: React.ReactNode;
};

export function PurchaseCompletedCard({
  purchase,
  routes,
  children,
}: PurchaseCompletedCardProps): ReactElement | null {
  const history = useHistory();
  const config = getConfig();

  if (!purchase.offering) {
    return null;
  }

  const showDashboardButton =
    purchase.offering.problemTypes?.includes('WEIGHT_LOSS') &&
    config.weightDashboardEnabled;

  const buttons: ConsultationCardProps['buttons'] = [
    {
      key: 'purchaseActiveCardViewButton',
      text: (
        <FormattedMessage
          defaultMessage="View treatment plan"
          description="Button that navigates to the treatment plan details page"
        />
      ),
      onClick: () => {
        history.push(routes.offering.plan(purchase.id));
      },
      level: showDashboardButton ? 'secondary' : 'primary',
    },
  ];

  if (showDashboardButton) {
    buttons.unshift({
      key: 'purchaseActiveCardWeightButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
      level: 'primary' as const,
    });
  }

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'purchaseCompletedCardText',
          text: (
            <FormattedMessage
              defaultMessage="Your treatment plan has now finished"
              description="Text informing users the status that their treatment is finished"
            />
          ),
        },
      ]}
      buttons={buttons}
    >
      {children}
    </ConsultationCardContentView>
  );
}

PurchaseCompletedCard.fragment = gql`
  fragment PurchaseCompletedCardPurchase on Purchase {
    id
    offering {
      id
      problemTypes
    }
  }
`;
