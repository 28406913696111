import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import {
  FulfillmentStatus,
  TreatmentStatus,
} from '@customer-frontend/graphql-types';
import { Card } from '../use-profile-cards';
import { findFirstFulfilledOrderDate } from '../utils';
import {
  getTreatmentStartedDateCard,
  TreatmentStartedDateCardProps,
} from './treatment-started-card';

export const useGetTreatmentStartedCard = (
  treatment?: {
    id: string;
    status: TreatmentStatus;
    customerStatedTreatmentStartedAt?: string | null | undefined;
    orders?: {
      fulfilledAt?: string | null | undefined;
      fulfillment?: FulfillmentStatus | null | undefined;
    }[];
  } | null,
): Card<TreatmentStartedDateCardProps> | null => {
  const featureFlagClient = useFeatureFlagClient();

  const firstOrderFulfilledDate = findFirstFulfilledOrderDate(
    treatment?.orders,
  );

  if (!treatment || !firstOrderFulfilledDate) {
    return null;
  }

  const isTreatmentActive = treatment.status === 'ACTIVE';
  const showTreatmentStartedDateCard =
    !treatment?.customerStatedTreatmentStartedAt && isTreatmentActive;

  if (showTreatmentStartedDateCard) {
    const isTreatmentCommencedEnabled = featureFlagClient.getBoolean(
      'TREATMENT_COMMENCED_DATE',
    );
    return isTreatmentCommencedEnabled
      ? getTreatmentStartedDateCard({
          props: {
            treatmentId: treatment.id,
            firstOrderFulfilledDate,
          },
          key: 'treatmentStartedDateCard',
        })
      : null;
  }

  return null;
};
