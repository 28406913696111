import { History } from 'history';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { ProblemType } from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';

const getContent = ({
  consultationId,
  history,
  problemType,
  isSyncConsult,
  isUpfrontPayment,
  routes,
}: {
  consultationId: string;
  history: History;
  problemType: ProblemType;
  isSyncConsult: boolean;
  isUpfrontPayment: boolean;
  routes: {
    consultation: {
      consultationPayment: (id: string) => string;
      collectAddress: (id: string) => string;
      upfrontPayment: (id: string) => string;
    };
  };
}): { text: ReactElement; buttonText: ReactElement; onClick: () => void } => {
  const config = getConfig();
  if (problemType === 'MENOPAUSE') {
    return {
      text: (
        <FormattedMessage
          defaultMessage="You’ve completed the quiz! Your next step is to pay for a consultation so we can connect you with a {isPrescriber, select, true {prescriber} other {practitioner}}."
          values={{
            isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
          }}
        />
      ),
      buttonText: <FormattedMessage defaultMessage="Continue to payment" />,
      onClick: () => {
        history.push(routes.consultation.consultationPayment(consultationId));
      },
    };
  }
  if (isSyncConsult) {
    return {
      text: (
        <FormattedMessage defaultMessage="Your answers indicate you could be suitable for our program. Your next step is to proceed to payment so you can continue your consult." />
      ),
      buttonText: (
        <FormattedMessage
          defaultMessage="Go to payment"
          description="Button on profile to go to payment"
        />
      ),
      onClick: () => {
        if (isUpfrontPayment && problemType === 'HAIR') {
          history.push(routes.consultation.upfrontPayment(consultationId));
        } else {
          history.push(routes.consultation.consultationPayment(consultationId));
        }
      },
    };
  }
  if (getConfig().collectAddressPage && problemType === 'WEIGHT_LOSS') {
    return {
      text: (
        <FormattedMessage
          defaultMessage="You’ve completed the quiz! Your next step is to confirm your details so we can connect you with a {isPrescriber, select, true {prescriber} other {practitioner}}."
          values={{
            isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
          }}
        />
      ),
      buttonText: <FormattedMessage defaultMessage="Confirm details" />,
      onClick: () => {
        history.push(routes.consultation.collectAddress(consultationId));
      },
    };
  }
  return {
    text: (
      <FormattedMessage
        defaultMessage="You've finished the quiz! Your next step is to pay for a consultation and have a {isPrescriber, select, true {prescriber} other {practitioner}} review your responses."
        description="Instructional text telling users to pay for their consult"
        values={{
          isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
        }}
      />
    ),
    buttonText: (
      <FormattedMessage
        defaultMessage="Continue to payment"
        description="Button that navigates to payment screen"
      />
    ),
    onClick: () => {
      history.push(routes.consultation.upfrontPayment(consultationId));
    },
  };
};

export const AwaitingPaymentCard: React.FC<{
  consultationId: string;
  problemType: ProblemType;
  isSyncConsult: boolean;
  isUpfrontPayment: boolean;
  routes: {
    consultation: {
      consultationPayment: (id: string) => string;
      collectAddress: (id: string) => string;
      upfrontPayment: (id: string) => string;
    };
  };
}> = ({
  consultationId,
  problemType,
  isSyncConsult,
  routes,
  isUpfrontPayment,
}) => {
  const history = useHistory();
  const { text, buttonText, onClick } = getContent({
    consultationId,
    history,
    problemType,
    isSyncConsult,
    isUpfrontPayment,
    routes,
  });

  return (
    <ConsultationCardContentView
      paragraphs={[
        {
          key: 'awaitingPaymentText',
          text: text,
        },
      ]}
      buttons={[
        {
          key: 'awaitingPaymentButton',
          text: buttonText,
          onClick,
        },
      ]}
    />
  );
};
