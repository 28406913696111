import { type ReactElement } from 'react';
import {
  Typography,
  Button,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  ProblemType,
  QuizCompletedCardContentQuizApplicationFragment,
} from '@customer-frontend/graphql-types';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { gql } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';
import { Logger } from '@customer-frontend/logger';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

interface QuizCompletedCardContentProps {
  quizApplication: QuizCompletedCardContentQuizApplicationFragment;
  existingQuizArchived?: boolean;
  problemType: ProblemType;
  routes: {
    quiz: {
      rejected: (id: string) => string;
      reset?: (id: string) => string;
    };
  };
  logger: Logger;
}

export function QuizCompletedCardContent({
  quizApplication,
  existingQuizArchived,
  problemType,
  routes,
  logger,
}: QuizCompletedCardContentProps): ReactElement {
  const config = getConfig();
  const history = useHistory();
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const quizResetEnabled = useFeatureFlagBoolean('FF_QUIZ_AUTO_RESET');

  const resetRoute = routes.quiz.reset;
  if (
    existingQuizArchived &&
    quizResetEnabled &&
    problemType === 'WEIGHT_LOSS'
  ) {
    return (
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Your answers indicate you could be suitable for our program. Your next step is to proceed to payment so you can continue your consult." />
        </Typography>

        <Button
          level="primary"
          isFullWidth
          onClick={(): void => {
            if (resetRoute) {
              history.push(resetRoute(quizApplication.id));
            } else {
              logger.error('Expected route for quiz reset to be present');
              notify.error({
                message: formatMessage({
                  defaultMessage:
                    'Something went wrong. Please contact support',
                }),
              });
            }
          }}
        >
          <FormattedMessage
            defaultMessage="Go to payment"
            description="Button on profile to go to payment"
          />
        </Button>
      </div>
    );
  }

  const hasNotEligibleQuizOutcome = quizApplication.outcomes?.some(
    (o) => o.__typename === 'NotEligibleQuizOutcome',
  );

  return (
    <div className="space-y-4">
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="Unfortunately, we've reviewed your information and determined {brandTitle} does not have a suitable treatment for you today."
          values={{
            brandTitle: config.title,
          }}
        />
      </Typography>

      {hasNotEligibleQuizOutcome && (
        <Button
          level="primary"
          isFullWidth
          onClick={(): void => {
            history.push(routes.quiz.rejected(quizApplication.id));
          }}
        >
          <FormattedMessage defaultMessage="View details" />
        </Button>
      )}
    </div>
  );
}

QuizCompletedCardContent.fragment = gql`
  fragment QuizCompletedCardContentQuizApplication on QuizApplication {
    id
    outcomes {
      ... on QuizOutcomeV2 {
        id
      }
    }
  }
`;
