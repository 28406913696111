import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';

type TreatmentReviewAwaitingDoctorCardProps = {
  consultationId: string;
  hasActiveWeightTreatment: boolean;
  hasChatThread: boolean;
  routes: {
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
  children: React.ReactNode;
};

export function TreatmentReviewAwaitingDoctorCard({
  consultationId,
  hasActiveWeightTreatment,
  hasChatThread,
  routes,
  children,
}: TreatmentReviewAwaitingDoctorCardProps): ReactElement {
  const config = getConfig();
  const history = useHistory();
  const paragraphs = [
    {
      key: 'treatmentReviewAwaitingDoctorText',
      text: (
        <FormattedMessage
          defaultMessage="Currently waiting to be assigned to a {isPrescriber, select, true {prescriber} other {practitioner}}. We will notify you when you have been assigned."
          description="Text informing users a practitioner will be assigned and they will be notified"
          values={{
            isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
          }}
        />
      ),
    },
  ];

  const buttons = [];
  if (hasChatThread) {
    buttons.push({
      key: 'treatmentReviewAwaitingDoctorChatButton',
      text: <FormattedMessage defaultMessage="Open chat" />,
      onClick: () => {
        history.push(routes.consultation.chat(consultationId));
      },
      level: 'primary' as const,
    });
    paragraphs.push({
      key: 'treatmentReviewAwaitingDoctorChatText',
      text: (
        <FormattedMessage
          defaultMessage="The {isPrescriber, select, true {prescriber} other {practitioner}} will reach out via chat to conduct the consult."
          description="Text informing users a practitioner will be assigned and they will be notified"
          values={{
            isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
          }}
        />
      ),
    });
  }
  if (hasActiveWeightTreatment && config.weightDashboardEnabled) {
    buttons.push({
      key: 'treatmentReviewAwaitingDoctorWeightButton',
      text: <FormattedMessage defaultMessage="View dashboard" />,
      onClick: () => {
        history.push(routes.weightLoss.dashboard);
      },
      level: hasChatThread ? ('secondary' as const) : ('primary' as const),
    });
  }

  return (
    <ConsultationCardContentView paragraphs={paragraphs} buttons={buttons}>
      {children}
    </ConsultationCardContentView>
  );
}
