import React from 'react';
import clsx from 'clsx';
import { FaChevronLeft } from 'react-icons/fa';
import { HiUserCircle, HiOutlineUserCircle } from 'react-icons/hi';
import logoUrl from '../../../assets/software-logo.svg';
import {
  SetBackButtonBehaviourContext,
  BackButtonBehaviour,
} from '@customer-frontend/services';
import { Link, useHistory } from 'react-router-dom';
import { primaryNavigationRoutes } from 'utils/routes';
import { useAuth } from '@customer-frontend/auth';
import { logger } from 'utils/logging';
import { Progress, SetProgressContext } from '@customer-frontend/navbar';
import { OTCCartProvider } from '@customer-frontend/order';
import { CartDrawer } from 'pages/treatment/cart/cart-drawer';
import { ShoppingCartButton } from './shopping-cart-button';
import { useShouldShowOtcCartNavButton } from 'utils/hooks';
import { Typography } from '@eucalyptusvc/design-system';
import { useLocation } from 'react-use';

export function NavBarLayout(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const subClasses = 'w-1/3 flex items-center';
  const { isLoggedIn, loggedInUser } = useAuth();
  const location = useLocation();
  const [progress, setProgress] = React.useState<Progress | null>(null);
  const [backButtonBehaviour, setBackButtonBehaviour] =
    React.useState<BackButtonBehaviour | null>(null);

  const isInAccountPage = location.pathname === primaryNavigationRoutes.account;

  if (!isLoggedIn) {
    return (
      <div>
        <div className="fixed w-full shadow-navbar z-10">
          <div className="select-none bg-white flex h-16 sm:h-20">
            <SoftwareLogo classes="w-full" />
          </div>
        </div>
        <section className="absolute pt-16 sm:pt-20 w-screen sm:w-full mx-auto min-h-screen h-fit bg-beige-100">
          {props.children}
        </section>
      </div>
    );
  }

  return (
    <OTCCartProvider>
      <SetBackButtonBehaviourContext.Provider value={setBackButtonBehaviour}>
        <SetProgressContext.Provider value={setProgress}>
          <section className="absolute pt-16 sm:pt-20 w-screen sm:w-full mx-auto min-h-full h-fit bg-beige-100">
            {props.children}
          </section>
        </SetProgressContext.Provider>
      </SetBackButtonBehaviourContext.Provider>
      <div className="fixed w-full shadow-navbar z-10">
        <div className="select-none bg-white flex h-16 sm:h-20">
          <div className={clsx(subClasses, 'justify-start')}>
            {backButtonBehaviour !== null && (
              <div className="mx-3 sm:mx-4">
                <BackButton onClick={backButtonBehaviour} />
              </div>
            )}
          </div>
          <SoftwareLogo classes="w-1/3" />
          <div className={clsx(subClasses, 'justify-end')}>
            <OTCShoppingCart />
            {loggedInUser?.firstName && (
              <Link
                to={primaryNavigationRoutes.account}
                className="font-medium flex items-center sm:mx-3 p-3 h-full sm:h-auto text-sm sm:text-base"
              >
                <span className="mr-2">
                  {isInAccountPage ? (
                    <HiUserCircle className="text-2xl" />
                  ) : (
                    <HiOutlineUserCircle className="text-2xl" />
                  )}
                </span>
                <Typography size="paragraph">
                  <span className="hidden sm:block">
                    Hey, {loggedInUser?.firstName}!
                  </span>
                </Typography>
              </Link>
            )}
          </div>
        </div>
        <ProgressIndicator progress={progress} />
      </div>
    </OTCCartProvider>
  );
}

const SoftwareLogo = ({ classes }: { classes: string }): React.ReactElement => {
  const history = useHistory();
  return (
    <div className={clsx(classes, 'flex items-center justify-center')}>
      <img
        className="w-24 sm:w-30 h-auto mt-2 cursor-pointer"
        src={logoUrl}
        alt="software logo"
        onClick={() => history.push(primaryNavigationRoutes.home)}
      />
    </div>
  );
};

const OTCShoppingCart = (): React.ReactElement | null => {
  const showOTCCartButton = useShouldShowOtcCartNavButton();
  if (!showOTCCartButton) {
    return null;
  }
  return (
    <>
      <ShoppingCartButton />
      <CartDrawer />
    </>
  );
};

function ProgressIndicator(props: {
  progress: Progress | null;
}): React.ReactElement | null {
  if (!props.progress) {
    return null;
  }

  if (props.progress.currentStepIndex < 0 || props.progress.lastStepIndex < 0) {
    logger.warn('Indices must be greater than or equal to 0.');
    return null;
  }

  if (props.progress.currentStepIndex > props.progress.lastStepIndex) {
    logger.warn('Current step index cannot be greater than last step index.');
    return null;
  }

  const widthPercentage =
    (100 * (props.progress.currentStepIndex + 1)) /
    (props.progress.lastStepIndex + 1);

  return (
    <div className="w-full h-0.5 absolute">
      <div className="w-full absolute h-full bg-gray-300" />
      <div
        style={{ width: `${widthPercentage}%` }}
        className="absolute h-full bg-black transition-all duration-500 ease-out"
      />
    </div>
  );
}

function BackButton(props: {
  onClick: BackButtonBehaviour;
}): React.ReactElement {
  return (
    <button
      className={clsx(
        'border',
        'border-gray-400',
        'hover:border-gray-600',
        'flex',
        'h-10',
        'items-center',
        'justify-center',
        'rounded',
        'w-10',
        'text-2xl',
        'cursor-pointer',
      )}
      onClick={props.onClick.callback}
    >
      <FaChevronLeft />
    </button>
  );
}
