import React from 'react';
import { ReactComponent as BellIcon } from '../../../../assets/icons/bell.svg';
import { ReactComponent as BellRingIcon } from '../../../../assets/icons/bell-ring.svg';
import {
  ReminderCardContent,
  activeReminder,
} from '@customer-frontend/consultation';
import { routes } from 'utils/routes';
import { Maybe } from '@customer-frontend/graphql-types';

export const Reminder = ({
  consultation,
}: {
  consultation: {
    id: string;
    reminders?:
      | {
          id: string;
          canceled: boolean;
          doneAt?: string | null | undefined;
          scheduledAt: string;
        }[]
      | null;
    patientQuestions?:
      | {
          id: string;
          createdAt: string;
        }[]
      | null;
    doctor?: {
      fullName?: Maybe<string>;
      fullClinicianName: string;
      shortClinicianName: string;
    } | null;
    allowPatientToSendMessageToDoctor: boolean;
  };
}): React.ReactElement | null => {
  const reminder = activeReminder(consultation);
  if (!reminder) {
    return null;
  }

  return (
    <ReminderCardContent
      consultation={consultation}
      reminder={reminder}
      scheduledIcon={BellIcon}
      triggeredIcon={BellRingIcon}
      consultRoute={routes.consultation.review(consultation.id)}
    />
  );
};
