import { type ReactElement } from 'react';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';
import { FormattedMessage } from 'react-intl';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useHistory } from 'react-router-dom';
import { SkipDoctorApproveButton } from '@customer-frontend/post-quiz';
import isToday from 'date-fns/isToday';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { gql } from '@apollo/client';
import {
  AwaitingDoctorCardPractitionerBookingFragment,
  Maybe,
  ProblemType,
} from '@customer-frontend/graphql-types';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { getZendeskRequestUrl } from '@customer-frontend/utils';
import { Typography } from '@eucalyptusvc/design-system';

type AwaitingDoctorCardProps = {
  isSyncConsult: boolean;
  allowPatientToSendMessageToDoctor: boolean;
  hasPractitionerMessaged: boolean;
  consultationId: string;
  isInNoPickUpQueue: boolean;
  practitionerBooking?: Maybe<AwaitingDoctorCardPractitionerBookingFragment>;
  routes: {
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
      phoneCall: (id: string) => string;
      scheduleCall: (id: string) => string;
    };
    profile: string;
  };
  problemType: ProblemType;
};

export function AwaitingDoctorCard({
  isSyncConsult,
  allowPatientToSendMessageToDoctor,
  hasPractitionerMessaged,
  consultationId,
  isInNoPickUpQueue,
  practitionerBooking,
  routes,
  problemType,
}: AwaitingDoctorCardProps): ReactElement {
  const config = getConfig();
  const isGb = config.countryCode === 'GB';
  const history = useHistory();
  let formattedPractitionerBookingString: string | null = null;

  const helpLink = getZendeskRequestUrl({ problemType });

  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  if (practitionerBooking) {
    const formattedStartTime = formatDate(
      mapBrandToAdaptersBrand(config.brand),
      practitionerBooking.windowStartAt,
      { hour: 'numeric' },
    );
    const formattedEndTime = formatDate(
      mapBrandToAdaptersBrand(config.brand),
      practitionerBooking.windowEndAt,
      { hour: 'numeric', timeZoneName: 'short' },
    );
    const formattedDay = isToday(new Date(practitionerBooking.windowStartAt))
      ? 'today'
      : formatDate(
          mapBrandToAdaptersBrand(config.brand),
          practitionerBooking.windowStartAt,
          { weekday: 'long' },
        );
    formattedPractitionerBookingString = `${formattedStartTime} - ${formattedEndTime} ${formattedDay}`;
  }
  let buttons;
  if (asyncConsultsEnabled && practitionerBooking) {
    buttons = [
      {
        key: 'rescheduleCallButton',
        text: <FormattedMessage defaultMessage="Reschedule call" />,
        onClick: () => {
          history.push(routes.consultation.scheduleCall(consultationId));
        },
        level: 'primary' as const,
      },
      {
        key: 'openChatButton',
        text: <FormattedMessage defaultMessage="Open consult chat" />,
        onClick: () => {
          history.push(routes.consultation.chat(consultationId));
        },
        level: 'secondary' as const,
      },
    ];
  } else if (asyncConsultsEnabled) {
    buttons = [
      {
        key: 'openChatButton',
        text: <FormattedMessage defaultMessage="Open consult chat" />,
        onClick: () => {
          history.push(routes.consultation.chat(consultationId));
        },
        level: 'primary' as const,
      },
    ];
  } else {
    buttons = [
      {
        key: 'rescheduleCallButton',
        text: (
          <FormattedMessage
            defaultMessage="Reschedule call"
            description="Button that navigates to reschedule a booking"
          />
        ),
        onClick: () => {
          history.push(routes.consultation.scheduleCall(consultationId));
        },
        level: 'primary' as const,
      },
      {
        key: 'openChatButton',
        text: (
          <FormattedMessage
            defaultMessage="Open chat"
            description="Button that navigates to chat screen"
          />
        ),
        onClick: () => {
          history.push(routes.consultation.chat(consultationId));
        },
        level: 'secondary' as const,
      },
    ];
  }

  if (isSyncConsult && allowPatientToSendMessageToDoctor) {
    if (isInNoPickUpQueue) {
      return (
        <ConsultationCardContentView
          paragraphs={[
            {
              key: 'awaitingDoctorText',
              text: (
                <FormattedMessage
                  defaultMessage="Your {isPrescriber, select, true {prescriber} other {practitioner}} called but wasn't able to reach you. Let's make sure they call at a time that works for you."
                  description="Status text telling patient that they missed the prescriber call and they should pick another time."
                  values={{
                    isPrescriber:
                      config.prescriberType === PrescriberType.PRESCRIBER,
                  }}
                />
              ),
            },
          ]}
          buttons={[
            {
              key: 'callScheduleButon',
              text: (
                <FormattedMessage
                  defaultMessage="Continue"
                  description="Button that navigates to call schedule screen"
                />
              ),
              onClick: () => {
                history.push(routes.consultation.phoneCall(consultationId));
              },
            },
          ]}
        />
      );
    }
    return (
      <>
        <ConsultationCardContentView
          paragraphs={[
            practitionerBooking
              ? {
                  key: 'bookingText',
                  text: (
                    <strong>
                      <FormattedMessage
                        defaultMessage="Your {isPrescriber, select, true {prescriber} other {practitioner}} will call you between {formattedPractitionerBookingString}."
                        description="Text showing the patient when their practitioner booking is scheduled"
                        values={{
                          isPrescriber:
                            config.prescriberType === PrescriberType.PRESCRIBER,
                          formattedPractitionerBookingString,
                        }}
                      />
                    </strong>
                  ),
                }
              : {
                  key: 'bookingText',
                  text: (
                    <strong>
                      <FormattedMessage
                        defaultMessage="Expect to receive a message in consult chat from your {isGb, select, true {prescriber} other {practitioner}} soon{isGb, select, true {.} other { (usually within 48 hours).}}"
                        values={{ isGb }}
                      />
                    </strong>
                  ),
                },
            {
              key: 'awaitingDoctorText0',
              text: asyncConsultsEnabled ? (
                <FormattedMessage
                  defaultMessage="If you have questions about your treatment, please send them in the chat. Your {isGb, select, true {prescriber} other {practitioner}} will answer them during your consult."
                  values={{ isGb }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="While you're waiting for your {isPrescriber, select, true {prescriber} other {practitioner}} to call, feel free to leave questions for them in the chat."
                  description="Status text telling patients that we are finding them a prescriber, and will notify them when found"
                  values={{
                    isPrescriber:
                      config.prescriberType === PrescriberType.PRESCRIBER,
                  }}
                />
              ),
            },
            {
              key: 'awaitingDoctorText1',
              text: asyncConsultsEnabled ? (
                <FormattedMessage
                  defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
                  values={{
                    a: (chunks) => (
                      <a
                        href={helpLink}
                        target="_blank"
                        rel="noreferrer"
                        className="text-link"
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              ) : (
                <></>
              ),
            },
            {
              key: 'awaitingDoctorText2',
              text: asyncConsultsEnabled ? (
                <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
              ) : (
                <></>
              ),
            },
          ]}
          buttons={buttons}
        />
        <SkipDoctorApproveButton
          consultationId={consultationId}
          profilePath={routes.profile}
        />
      </>
    );
  }

  if (
    !isSyncConsult &&
    asyncConsultsEnabled &&
    allowPatientToSendMessageToDoctor
  ) {
    const paragraphs = [
      {
        key: 'bookingText',
        text: (
          <strong>
            <FormattedMessage
              defaultMessage="Expect to receive a message in consult chat from your {isGb, select, true {prescriber} other {practitioner}} soon{isGb, select, true {.} other { (usually within 48 hours).}}"
              values={{ isGb }}
            />
          </strong>
        ),
      },
    ];

    if (hasPractitionerMessaged) {
      paragraphs.push({
        key: 'awaitingDoctorText0',
        text: asyncConsultsEnabled ? (
          <FormattedMessage
            defaultMessage="If you have questions about your treatment, please send them in the chat. Your {isGb, select, true {prescriber} other {practitioner}} will answer them during your consult."
            values={{ isGb }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="While you're waiting for your {isPrescriber, select, true {prescriber} other {practitioner}} to call, feel free to leave questions for them in the chat."
            description="Status text telling patients that we are finding them a prescriber, and will notify them when found"
            values={{
              isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        ),
      });
    }

    paragraphs.push({
      key: 'awaitingDoctorText1',
      text: (
        <FormattedMessage
          defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
          values={{
            a: (chunks) => (
              <a
                href={helpLink}
                target="_blank"
                rel="noreferrer"
                className="text-link"
              >
                {chunks}
              </a>
            ),
          }}
        />
      ),
    });
    paragraphs.push({
      key: 'awaitingDoctorText2',
      text: (
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Please note: Your orders are paused until this consult is complete." />
        </Typography>
      ),
    });

    return (
      <ConsultationCardContentView
        paragraphs={paragraphs}
        buttons={hasPractitionerMessaged ? buttons : []}
      />
    );
  }

  return (
    <ConsultationCardContentView
      paragraphs={
        asyncConsultsEnabled && !isSyncConsult
          ? [
              {
                key: 'awaitingDoctorText0',
                text: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage
                      defaultMessage="Expect to receive an update from your {isGb, select, true {prescriber} other {practitioner}} soon{isGb, select, true {.} other { (usually within 48 hours).}}"
                      values={{ isGb }}
                    />
                  </Typography>
                ),
              },
              {
                key: 'awaitingDoctorText1',
                text: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage="If you have any questions about your consult, treatment or orders, please reach out to our <a>customer service team</a>."
                      values={{
                        a: (chunks) => (
                          <a
                            href={helpLink}
                            target="_blank"
                            rel="noreferrer"
                            className="text-link"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                ),
              },
              {
                key: 'awaitingDoctorText2',
                text: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage defaultMessage="Please note: Your orders are paused until this consult is complete." />
                  </Typography>
                ),
              },
            ]
          : [
              {
                key: 'awaitingDoctorText',
                text: (
                  <FormattedMessage
                    defaultMessage="You will be assigned to a {isGb, select, true {prescriber} other {practitioner}} soon. We'll be in touch to explain next steps once you have been assigned."
                    description="Status text telling patients that we are finding them a prescriber, and will notify them when found"
                    values={{ isGb }}
                  />
                ),
              },
            ]
      }
      buttons={[]}
    />
  );
}

AwaitingDoctorCard.fragment = gql`
  fragment AwaitingDoctorCardPractitionerBooking on PractitionerBooking {
    id
    windowStartAt
    windowEndAt
  }
`;
